<template>
  <a-modal style="top: 8px;" :width="1000" v-model="visible" :footer="null"
           :title="dialogTitle"
           :maskClosable="false">

    <!-- 查询表单 -->
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:6}" :wrapper-col="{xs:18}">
      <a-row :gutter="8">
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="关联单号">
            <a-input v-model="searchData.dealNo" placeholder="请输入关联单号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="交易类型">
            <DictSelect
              field="rebateDealType"
              :value.sync="searchData.dealType"
              style="width: 100%"
              placeholder="请选择交易状态"
            ></DictSelect>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="金额类型">
            <DictSelect
              field="amountType"
              :value.sync="searchData.amountType"
              style="width: 100%"
              placeholder="请选择金额状态"
            ></DictSelect>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="6" :xxl="6" :sm="12">
          <a-form-model-item label="交易时间">
            <DateRange :startTime.sync="searchData.startDate" :endTime.sync="searchData.endDate"></DateRange>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
            <a-button type="primary" @click="getList()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table :columns="columns" :data-source="tableData" :pagination="page" @change="changeTable"
             :rowKey="record => record.id" bordered :maskClosable="false">
     <span slot="dealType" slot-scope="text">
      <a-tag color="" v-if="text == 1">支付</a-tag>
      <a-tag color="" v-if="text == 2">充值</a-tag>
      <a-tag color="" v-if="text == 3">订单取消</a-tag>
      <a-tag color="" v-if="text == 4">订单关闭</a-tag>
      <a-tag color="" v-if="text == 5">平台操作</a-tag>
      <a-tag color="" v-if="text == 6">订单修改时退回</a-tag>
      <a-tag color="" v-if="text == 7">订单删除</a-tag>
      <a-tag color="" v-if="text == 8">审核拒绝</a-tag>
      <a-tag color="" v-if="text == 9">折扣过期</a-tag>
      <a-tag color="" v-if="text == 10">折扣延时</a-tag>
      <a-tag color="" v-if="text == 11">折扣删除</a-tag>
      <a-tag color="" v-if="text == 12">发货单取消</a-tag>
      <a-tag color="" v-if="text == 13">发货单驳回</a-tag>
    </span>
      <span slot="amountType" slot-scope="text">
      <a-tag color="#87d068" v-if="text == 1">收入</a-tag>
      <a-tag color="#f50" v-if="text == 2">支出</a-tag>
    </span>

      <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
      <span slot="dealAmount" slot-scope="text, row">{{row.amountType==1?"":"-"}} {{ text | formatMoney }} </span>
      <span slot="balance" slot-scope="text"> {{ text | formatMoney }} </span>
      <span slot="dealNo" slot-scope="text,row">
        <a @click="checkOrderInfo(row.dealNo)">{{ text }}</a>
      </span>

    </a-table>

    <div style="text-align: right;margin-top: 14px;">
      <a-button type="default" @click="toCancel">取消</a-button>
      <!--      <a-button type="primary" @click="toSubmit">确认</a-button>-->
    </div>
    <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
  </a-modal>

</template>

<script>
  import OrderShipInfoEditModal from "@/views/order_ship_info/components/OrderShipInfoEditModal";
  import OrderInfoCheckModal from "@/views/order_info/components/OrderInfoCheckModal";
  import FinanceReceiptInfoModal from '@/views/finance_receipt/components/FinanceReceiptInfoModal';
  const qs = require('qs')

  const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  export default {

    name: "DealerRebatePayDetailed",
    components: {
      OrderInfoCheckModal,
    },
    data() {
      return {
        columns: [
          {
            title: '交易时间',
            dataIndex: 'dealDate',
            align: 'center',
            key: 'dealDate',
          },
          {
            title: '交易前折扣金额',
            dataIndex: 'amount',
            align: 'center',
            key: 'amount',
            scopedSlots: {customRender: 'amount'}
          },
          {
            title: '交易金额',
            dataIndex: 'dealAmount',
            align: 'center',
            key: 'dealAmount',
            scopedSlots: {customRender: 'dealAmount'}
          },
          {
            title: '交易后折扣余额',
            dataIndex: 'balance',
            align: 'center',
            key: 'balance',
            scopedSlots: {customRender: 'balance'}
          },
          {
            title: '交易类型',
            dataIndex: 'dealType',
            align: 'center',
            key: 'dealType',
            scopedSlots: {customRender: 'dealType'}
          },
          {
            title: '金额类型',
            dataIndex: 'amountType',
            align: 'center',
            key: 'amountType',
            scopedSlots: {customRender: 'amountType'}
          },
          {
            title: '关联单号',
            dataIndex: 'dealNo',
            align: 'center',
            key: 'dealNo',
            scopedSlots: {customRender: 'dealNo'}
          },

        ],
        visible: false,
        tableData: [],
        searchData: {}, // 搜索条件
        scroll: {y: 600},
        tableLoading: false, //表格loading
        page: {
          current: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: true,
          showTotal: () => `共${this.page.total}条`,
        },
        selectedRowKeys: [],
        baseUrl: process.env.VUE_APP_API_BASE_URL,
        selectedRows: [],
        dealerId: 0,
        dialogTitle:''
      }
    },
    methods: {
      // 查询
      getList() {
        this.getData();
      },

      // 重置
      reset() {
        this.searchData = {}
        this.regionalOffice = []
        this.getData();
      },

      //打开上游单据
      checkOrderInfo(orderCode) {
        const data = {
          orderCode: orderCode,
        }
        this.$refs.OrderInfoCheckModal.isShowByOrderCode(data, 'check')
      },
      setRowData(row) {
        this.visible = true
        this.dealerId = row.id
        this.dialogTitle = '【'+row.dealerName +'】折扣金额流水';
        this.getData()
      },

      getData() {
        let params = {
          dealerId: this.dealerId,
          pageSize: this.page.pageSize,
          pageNumber: this.page.current
        }
        const postData = Object.assign(params, this.searchData)
        // this.axios.get('/api/dealer/dealer/dealerPayRecord/dealerById', {params}).then(res => {
        this.axios
          .get(`/api/dealer/dealer/dealerRebatePayRecord/list?${qs.stringify(postData, { arrayFormat: 'repeat' })}`)
          .then((res) => {
          if (res.code === 200) {
            const {total, records} = res.body
            this.page.total = total
            this.tableData = records
          }
        })
      },

      // table分页
      changeTable(pagination) {
        this.page = pagination
        this.getData()
      },

      toCancel() {
        this.visible = false;
      }
    }

  }
</script>

<style scoped>

</style>